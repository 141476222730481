<!-- 
    页面 人脸管理 (这里的Table 用了 elemnt-ui)
-->
<template>
  <div id="sip-list-page" class="data-list-container dark-layout">
    <vx-card>
      <div class="table_height_container">
        <el-row class="search-row" type="flex">
          <el-col :span="21">
            <el-select v-model="houseForm.building" size="mini" :placeholder="houseFormPlaceholder.buildingName || '请选择楼栋'" class="rp_10" @change="buildingChange">
              <el-option v-for="item in buildingArr" :key="item.index" :label="`${item.building_name}`" :value="item.space_code"></el-option>
            </el-select>
            <el-select v-model="houseForm.unit" size="mini" :placeholder="houseFormPlaceholder.unitName || '请选择单元'" class="rp_10" @change="unitChange">
              <el-option v-for="item in unitArr" :key="item.index" :label="`${item.unit_name}`" :value="item.space_code"></el-option>
            </el-select>
            <el-select v-model="houseForm.house" size="mini" filterable :placeholder="houseFormPlaceholder.houseName || '请选择房屋'" class="rp_10" @change="houseChange">
              <el-option v-for="item in houseArr" :key="item.index" :label="item.house_no" :value="item.space_code"></el-option>
            </el-select>
            <el-button class="search-btn" size="mini" @click="searchAction">搜索</el-button>
          </el-col>
        </el-row>
        <el-divider content-position="left">呼叫调整</el-divider>
        <el-row class="search-row" style="margin-bottom: 20px;">
          <el-col :span="18">
            <div class="flex title">
              <h5>小区:</h5><span>{{villageName ? villageName : "暂无" }}</span>
              <h5>楼栋:</h5><span>{{buildingName ? buildingName : "暂无" }}</span>
              <h5>单元:</h5><span>{{unitName ? unitName : "暂无" }}</span>
              <h5>房间:</h5><span>{{houseName ? houseName : "暂无" }}</span>
            </div>
          </el-col>
          <el-col :span="6">
            <!-- 操作按钮 -->
            <vs-button style="float:right" size="small" type="relief" @click="save()" :disabled="isClick">保存</vs-button>
            <vs-button v-if="isEdit"  style="float:right" class="mr-3" size="small" type="relief" @click="edit()" :disabled="isClick">编辑</vs-button>
            <vs-button v-else style="float:right" class="mr-3"  size="small" type="relief" @click="cancel()" :disabled="isClick">取消</vs-button>
          </el-col>
        </el-row>
        <el-row class="search-row" type="flex">
          <el-col :span="24">
            <ul class="th">
              <li style="text-align: left; width: 60px!important;">头像</li>
              <li>姓名</li>
              <li>号码</li>
              <li>状态</li>
              <li>顺序</li>
              <li>是否启动</li>
            </ul>
            <div style="display: block;height: 1px;width: 100%; margin: 10px 0; background-color: #DCDFE6; position: relative;"></div>
            <draggable v-if="sipList.length > 0 && isRefresh" class="list-group" tag="ul" v-model="sipList" v-bind="dragOptions" :move="onMove" @start="isDragging=true" @end="isDragging=false">
              <transition-group type="transition" :name="'flip-list'">
                <li class="list-group-item" v-for="element in sipList" :key="element.order">
                  <div class="wl-20" style="text-align: left; width: 60px!important;">
                    <div v-if="element.headPic == ''" style="width: 50px; height: 50px; background-color: #C0C4CC;"></div>
                    <img v-else :src="prefixHost + element.headPic" style="display:inline; width: 50px; height: 50px"/>
                  </div>
                  <div class="wl-20">{{element.peopleName || "暂无"}}</div>
                  <div class="wl-20">{{element.peopleTel  || "暂无"}}</div>
                  <div class="wl-20">
                    <feather-icon :icon="element.fixed ? 'AnchorIcon' : 'ActivityIcon'" svgClasses="w-4 h-4" aria-hidden="true"></feather-icon>
                  </div>
                  <div class="wl-20">
                     <el-tag type="warning">{{ element.order }}</el-tag>
                  </div>
                  <div class="wl-20">
                    <el-switch v-model="element.call_order_bol" active-color="#13ce66" inactive-color="#ff4949" @change="changeCallIsOn($event, element)" :disabled="switchDisaled"></el-switch>
                  </div>
                </li>
              </transition-group>
            </draggable>
            <el-col v-else style="text-align: center;">
              <span>暂无数据,请重新查询</span>
            </el-col>
          </el-col>
        </el-row>
      </div>
    </vx-card>
  </div>
</template>

<script>
import draggable from "vuedraggable";

export default {
  data() {
    return {
      // 图片前缀
      prefixHost: this.$cFun.getUrlPrefix(),
      villageCode: "",
      villageName: "",
      buildingName: "",
      unitName: "",
      houseName: "",
      buildingArr: [],
      unitArr: [],
      houseArr: [],
      // 房屋表单
      houseForm: {
        ph_id: "",
        people_id: "",
        village: "",
        telephone: "",
        building: "",
        personType: "",
        main_call: false,
        unit: "",
        unit_code: "",
        relationshipWithHouseHold: "",
        house: "",
        house_code: "",
        houseUse: "",
        auth_start_time: new Date(),
        auth_expire_time: "2099-12-31 00:00:00",
      },
      // 房屋表单占位符
      houseFormPlaceholder: {
        placeholder: "请选择",
        buildingName: "",
        unitName: "",
        houseName: "",
      },
      drag: false,
      //定义要被拖拽对象的数组
      sipList: [
      ],
      sipListCopy: [
      ],
      editable: true,
      isDragging: false,
      delayedDragging: false,
      isEdit: true,
      isRefresh: true,
      switchDisaled: true,
      isClick: true
    };
  },
  components: {
    draggable,
  },
  computed: {
    dragOptions() {
      return {
        animation: 0,
        group: "description",
        disabled: !this.editable,
        ghostClass: "ghost",
      };
    },
    listString() {
      return JSON.stringify(this.list, null, 2);
    },
  },
  mounted() {
    //为了防止火狐浏览器拖拽的时候以新标签打开，此代码真实有效
    document.body.ondrop = function (event) {
      event.preventDefault();
      event.stopPropagation();
    };
  },
  watch: {
    isDragging(newValue) {
      if (newValue) {
        this.delayedDragging = true;
        return;
      }
      this.$nextTick(() => {
        this.delayedDragging = false;
      });
    },
    "$store.state.auth.space.space_code"(val) {
      this.villageCode = this.$store.state.auth.space.space_code;
      this.villageName = this.$store.state.auth.space.space_name;
      this.getBuildingList();
      this.$router.go(0)
    },
  },
  methods: {
    // 获取楼栋列表
    async getBuildingList() {
      try {
        let params = {
          villageCode: this.villageCode,
          pageNum: 1,
          pageSize: 999,
        };
        let result = await this.$apis.ODC("获取楼栋列表", params);
        if (this.$apis.isOk(result)) {
          this.buildingArr = result.data.buildingList;
          this.buildingArrTemp = result.data.buildingList;
        } else {
          this.$cFun.showNotify(
            this.$vs.notify,
            "warning",
            this.$apis.getResMessage(result)
          );
        }
      } catch (err) {
        this.$cFun.showNotify(
          this.$vs.notify,
          "danger",
          err.message ? err.message : err
        );
      }
    },
    // 获取单元列表
    async getUnitList(buildingCode) {
      try {
        let params = {
          buildingCode: buildingCode,
          pageNum: 1,
          pageSize: 999,
        };
        let result = await this.$apis.ODC("获取单元列表", params);
        if (this.$apis.isOk(result)) {
          return result.data.unitList;
        } else {
          this.$cFun.showNotify(
            this.$vs.notify,
            "warning",
            this.$apis.getResMessage(result)
          );
        }
      } catch (err) {
        this.$cFun.showNotify(
          this.$vs.notify,
          "danger",
          err.message ? err.message : err
        );
      }
    },
    // 获取房间列表
    async getHouseList(unitCode) {
      try {
        let params = {
          unitCode: unitCode,
          pageNum: 1,
          pageSize: 999,
        };
        let result = await this.$apis.ODC("获取房屋列表", params);
        if (this.$apis.isOk(result)) {
          return result.data.houseList;
        } else {
          this.$cFun.showNotify(
            this.$vs.notify,
            "warning",
            this.$apis.getResMessage(result)
          );
        }
      } catch (err) {
        this.$cFun.showNotify(
          this.$vs.notify,
          "danger",
          err.message ? err.message : err
        );
      }
    },
    // 楼栋被选择的时候
    async buildingChange(val) {
      console.log("buildingChange");
      //检索查询名称
      this.buildingArr.map(item=>{
        if (item.space_code == val) {
          this.buildingName = item.building_name
        }
      })

      this.houseForm.unit = "";
      this.houseForm.house = "";
      this.houseArr = [];
      let result = await this.getUnitList(val);
      this.unitArr = result;
      this.unitArrTemp = result;
    },
    // 单元
    async unitChange(val) {
      this.houseForm.house = "";
       //检索查询名称
      this.unitArr.map(item=>{
        if (item.space_code == val) {
          this.unitName = item.unit_name
        }
      })
      let result = await this.getHouseList(val);
      this.houseArr = result;
    },
    // 房间
    async houseChange(val) {
      //检索查询名称
      this.houseArr.map(item=>{
        if (item.space_code == val) {
          this.houseName = item.house_no
        }
      })
    },
    // 请求接口
    async searchAction() {
      console.log("searchAction");
      this.isClick = false
      if (this.houseForm.house) {
        await this.getHouseSipInfo()
      } else {
        this.$cFun.showNotify(this.$vs.notify,"warning", "请选择指定房屋");
      }
    },
    async getHouseSipInfo() {
      try {
        let params = {
          house_code: this.houseForm.house
        };
        let result = await this.$apis.ODC("获取房屋内的sip呼叫信息", params);
        if (this.$apis.isOk(result)) {
          let tempArr = result.sipList.map((item, index) => {
            let peopleName = item.peopleInfo.people_name
            let headPic = item.peopleInfo.head_pic
            let peopleTel = item.phone
            let peopleID = item.people_id
            let call_order_is_on = item.call_order_is_on
            let call_order_bol = item.call_order_is_on == 1 ? true : false
            return { order: index + 1, fixed: true, call_order_bol,  peopleName,  peopleTel , call_order_is_on, peopleID,  headPic };
          })
          if (tempArr.length == 0) {
            this.isClick = true
          }
          this.sipList = tempArr
        } else {
          this.$cFun.showNotify(this.$vs.notify,"warning",this.$apis.getResMessage(result));
        }
      } catch (err) {
        this.$cFun.showNotify(this.$vs.notify,"danger",err.message ? err.message : err );
      }
    },
    async cancel() {
      this.isEdit = true
      this.isRefresh = false
      this.switchDisaled = true
      this.sipList = this.sipListCopy
      this.isRefresh = true
    },
    orderList() {
      this.list = this.list.sort((one, two) => {
        return one.order - two.order;
      });
    },
    onMove({ relatedContext, draggedContext }) {
      const relatedElement = relatedContext.element;
      const draggedElement = draggedContext.element;
      return (
        (!relatedElement || !relatedElement.fixed) && !draggedElement.fixed
      );
    },
    edit() {
      this.isEdit = false
      this.switchDisaled = false
      this.sipListCopy = this.sipList
      let temp = JSON.parse(JSON.stringify(this.sipList))
      let tempArr = temp.map((item, index) => {
        if (item.call_order_is_on == 1) {
          item.fixed = false
        }
        return item
      })
      this.isRefresh = false
      this.sipList = tempArr
      this.isRefresh = true
    },
    async save() {
      try {
        let close_people_list = []
        let sip_list = []
        this.sipList.map(item => {
          if (item.call_order_bol == true) {
            sip_list.push({"phone": item.peopleTel , "people_id": item.peopleID})
          } else {
            close_people_list.push(item.peopleID)
          }
        })
        let params = {
          house_code: this.houseForm.house,
          close_people_list: close_people_list,
          sip_list: sip_list
        };
        let result = await this.$apis.ODC("更新房屋呼叫顺序排序", params);
        if (this.$apis.isOk(result)) {
          this.sipListCopy = []
          this.isEdit = true
          this.switchDisaled = true
          this.$cFun.showNotify(this.$vs.notify,"success",this.$apis.getResMessage(result));
          await this.getHouseSipInfo()
        } else {
          this.$cFun.showNotify(this.$vs.notify,"warning",this.$apis.getResMessage(result));
        }
      } catch (err) {
        this.$cFun.showNotify(this.$vs.notify,"danger",err.message ? err.message : err );
      }
    },
    changeCallIsOn: function(val, obj){
      let temp = JSON.parse(JSON.stringify(this.sipList))
      let tempArr = temp.map((item, index) => {
        if (item.order == obj.order) {
          item.fixed = val ? false : true
        }
        return item
      })
      this.sipList = tempArr
		}
  },
  async created() {
    // 请求数据
    this.villageCode = this.$store.state.auth.space.space_code;
    this.villageName = this.$store.state.auth.space.space_name;
    this.getBuildingList();
  },
};
</script>

<style lang="scss" scope="scope">
#sip-list-page {
  .search-row {
    margin-bottom: 5px;
    .search-select {
      margin-right: 5px;
    }
    .search-input {
      width: 200px;
      margin-right: 5px;
    }
    .search-btn {
      &:hover {
        background-color: rgba(var(--vs-primary), 0.1);
        border-color: rgba(var(--vs-primary), 0.1);
      }
    }
  }
  .table_theme {
    .el-table__expanded-cell[class*="cell"] {
      padding: 0px;
    }
    .el-table__body tr {
      &:hover {
        cursor: pointer;
      }
    }
  }
  .table_height_container {
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 200px);
  }
  .el-button.is-disabled.el-button--text {
    text-decoration: line-through;
  }
  .rp_10 {
    padding-right: 10px;
  }
  /*被拖拽对象的样式*/
  .item {
    padding: 6px;
    background-color: #fdfdfd;
    border: solid 1px #eee;
    margin-bottom: 10px;
  }
  /*选中样式*/
  .chosen {
    border: solid 2px #3089dc !important;
  }
  .flip-list-move {
    transition: transform 0.5s;
  }

  .no-move {
    transition: transform 0s;
  }

  .ghost {
    opacity: 0.5;
    background: #c8ebfb;
  }

  .list-group {
    min-height: 20px;
  }

  .list-group-item {
    display: flex;
    padding: 6px;
    background-color: #fdfdfd;
    border: solid 1px #eee;
    cursor: move;
  }
  .th > li {
    display: inline-block;
    width: 18%;
    text-align: center;
  }
  .list-group-item i {
    cursor: pointer;
  }
  .title > span {
    padding-left: 5px;
    padding-right: 5px;
    line-height: 30px;
    font-weight: bold;
  }
  .title > h5 {
     line-height: 30px;
  }
  .wl-20{
    display:inline-block; width:18%; height: 50px; line-height: 50px; text-align: center; overflow: hidden;
  }
  .badge {
    display: inline-block;
    min-width: 10px;
    height: 17px;
    padding: 3px 7px;
    font-size: 12px;
    font-weight: bold;
    line-height: 1;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    background-color: #777;
    border-radius: 10px;
  }
}
</style>